import { useDispatch, useSelector } from "react-redux";
import React, { useMemo } from "react";

import { Box, Button, Flex, Heading } from "@chakra-ui/react";
import { useExamViewContext } from "./Context";
import { countBy, groupBy, map } from "lodash";
import { TagComponent } from "./MainView";
import { useHistory } from "react-router-dom";

export const ExamSummaryView = () => {
    const {
        testId,
        analysisbutton,
        testData,
        testAttempt: { sectionsKeyedData, questionResponseData, attemptData },
    } = useExamViewContext();
    const history = useHistory()

    const sectionGrouped = useMemo(
        () => groupBy(questionResponseData, "sectionId"),
        [questionResponseData]
    );


    const toAnalysis = () => {
        history.push("/exam/report/analysis?testId=" + testId + "&testAttemptId=" + attemptData?._id)
    }

    const closeWindow = () => {
        console.log("first")
        if (typeof window !== "undefined") {
            try {
                console.log("third")
                localStorage.setItem("testId", testId);
                localStorage.setItem("attemptId", attemptData._id);
                if (window.flutter_inappwebview) {
                    console.log("Fourth")
                    window.flutter_inappwebview.callHandler('Rankplus', 'onExit');
                }
            } catch (error) { console.log(error) }
            console.log("Second")
            window.close();
            window.ReactNativeWebView?.postMessage(
                JSON.stringify({
                    submit: true,
                    testId: testId,
                    testAttemptId: attemptData._id,
                })
            );

        }
    };

    return (
        <Box flex={1} display="flex" alignItems="center" justifyContent="center">
            <Box>
                <Heading textAlign="center">
                    {testData?.name?.en}
                </Heading>
                <Heading mt={10} fontSize="lg" textAlign="center" color="blue.300">
                    Test Submitted Successfully
                </Heading>
              
                <Flex align="center" flexDir={"column"} mt={4} justify="center">
                    <Button colorScheme='blue' onClick={closeWindow} >
                        Close this window
                    </Button>

                    {analysisbutton === "true" ? <Button colorScheme='green' my={5} onClick={toAnalysis} >
                        View Analysis
                    </Button> : null
                    }
                </Flex>
            </Box>
            <br />
        </Box>
    );
};
