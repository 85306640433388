import React, { useEffect, useMemo, useRef, useState } from "react";
import { Box, Button, Flex, HStack, Input, Modal, ModalHeader, ModalOverlay, ModalFooter, ModalContent, Tag, Text, VStack, ModalBody, Divider } from "@chakra-ui/react";
import ReactPlayer from "react-player/youtube";
import { useHistory, useLocation } from "react-router-dom";
import { AiFillPlayCircle } from "react-icons/ai";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { IoPauseOutline, IoPlayOutline } from "react-icons/io5";
import { round } from "lodash";
import { BiExitFullscreen, BiFullscreen } from "react-icons/bi";
// import { GoPrimitiveDot } from "react-icons/go";
import { useDispatch, useSelector } from "react-redux";
import { LoadingRef } from "../App/AppProvider";
import { resetPageDetailsData } from "../redux/reducers/UI";
import { useCallback } from "react";
import { Feedback } from "../routes/Feedback";

const overlayStyle = {
    height: '100%',
    width: '100%',
    position: 'absolute',
    top: '0px',
    bottom: '0px',
    backgroundColor: 'rgb(0,0,0)'
}

export function useInterval(callback, delay) {
    const savedCallback = useRef();

    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    useEffect(() => {
        function tick() {
            savedCallback.current();
        }
        if (delay !== null) {
            let id = setInterval(tick, delay);
            return () => clearInterval(id);
        }
    }, [callback, delay]);
}

export const YoutubeView = () => {
    const [progressData, setProgressData] = useState({});
    const [isVideoPlayingPre, setIsVideoPlayingPre] = useState(false);
    const [playbackRate, setPlaybackRate] = useState(1);
    const [videoData, changeVideoData] = useState({
        volume: 80,
        playing: true,
    });

    const { videoDetail, lectureData } = useSelector((s) => ({
        videoDetail: s.UI.pageDetailsData,
        lectureData: s.feedback.lectureData,
    }));

    const [video, setVideo] = useState({ data: { value: '', } });
    const [lectureModal, setLectureModal] = useState(false);

    const handle = useFullScreenHandle();
    const history = useHistory();
    const dispatch = useDispatch()

    useEffect(() => {
        if (videoDetail?.youtubeId) {
            setVideo({
                data: {
                    value: videoDetail.youtubeId
                }
            })
        } else {
            LoadingRef.current.showToast({
                title: "Cannot Find Video",
                position: "top",
            });
            history.push('/');
        }
        return () => dispatch(resetPageDetailsData());
    }, [history, dispatch, videoDetail])

    const toggleFullscreen = () => {
        if (handle.active) {
            handle.exit();
        } else {
            handle.enter();
        }
    };

    const ref = useRef();

    const playVideo = () => {
        changeVideoData((d) => ({ ...d, playing: true }));
    };

    const pauseVideo = () => {
        changeVideoData((d) => ({ ...d, playing: false }));
    };
    const videoReady = () => { };
    const location = useLocation();

    const handleProgress = (state) => {
        setProgressData(state);
    };

    const handleDuration = (state) => {
    };

    const handleEnded = () => {
        if (lectureData && lectureData?.lectureId) {
            setLectureModal(true);
        }
        setIsVideoPlayingPre(false);
        handlePause();
    };

    const handlePlay = () => {
        playVideo();
        window.setTimeout(() => { setIsVideoPlayingPre(true) }, 400);
    };

    const handlePause = () => {
        setIsVideoPlayingPre(false);
        window.setTimeout(pauseVideo, 400);
    };

    const handleLiveBt = () => {
        ref.current.seekTo(1, 'fraction')
    }

    const isInFullMode = useMemo(() => handle.active, [handle.active]);
    return (
        <>
            <FullScreen handle={handle}>
                <VStack align='stretch' h='100vh' spacing={0}>
                    <Box h="90vh" position={"relative"}>
                        <ReactPlayer
                            style={{ background: "black" }}
                            ref={ref}
                            url={`https://www.youtube.com/embed/${video.data.value}`}
                            width="100%"
                            height="100%"
                            volume={videoData.volume / 100}
                            playing={videoData?.playing}
                            playbackRate={playbackRate}
                            playsinline={true}
                            controls
                            config={{
                                playerVars: {
                                    controls: 0,
                                    disablekb: 0,
                                    enablejsapi: 1,
                                    iv_load_policy: 3,
                                    fs: 0,
                                    rel: 0,
                                    modestbranding: 1,
                                    origin: location.origin,
                                    widget_referrer: location.origin,
                                },
                            }}
                            onReady={videoReady}
                            onProgress={handleProgress}
                            onDuration={handleDuration}
                            onEnded={handleEnded}
                            onPlay={handlePlay}
                            onPause={handlePause}
                        />

                        <HStack
                            justifyContent="center"
                            alignItems="center"
                            pos="absolute"
                            top={0}
                            bottom={0}
                            right={0}
                            left={0}
                            zIndex="9"
                            onClick={isVideoPlayingPre ? pauseVideo : playVideo}
                            cursor="pointer"
                            background=""
                        >
                            {isVideoPlayingPre ? null : (
                                <AiFillPlayCircle color="white" fontSize="90pt" />
                            )}
                        </HStack>

                        <Box
                            transition={isVideoPlayingPre && 'all 0.2s'}
                            style={ref.current?.player?.onDurationCalled && !isVideoPlayingPre ? { ...overlayStyle } : {}}>
                        </Box>
                    </Box>
                    <Box flex={1} bg='black' px={4}>
                        <Flex align='center' justify='space-between' w='full'>
                            <Box flexGrow={1}>
                                <Slider player={ref.current} progressData={progressData} />
                            </Box>
                            <Button color={'white'} variant="flushed" boxShadow='none!important' fontSize='xl' alignSelf='end' onClick={toggleFullscreen}>
                                {
                                    isInFullMode ?
                                        <BiExitFullscreen />
                                        :
                                        <BiFullscreen />
                                }
                            </Button>
                        </Flex>
                        <Flex justify='space-between'>
                            <HStack spacing={2}>
                                <PlayPauseBt isPause={!videoData.playing} handlePause={handlePause} handlePlay={handlePlay} />
                                <Duration handleLiveBt={handleLiveBt} isInFullMode={isInFullMode} player={ref.current} />
                            </HStack>
                        </Flex>
                    </Box>
                </VStack>
            </FullScreen>
            <Modal isOpen={lectureModal} size='lg'
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Add New Topic</ModalHeader>
                    <Divider />
                    <ModalBody>
                        <Feedback setLectureModal={setLectureModal} lectureId={lectureData?.lectureId} />
                    </ModalBody>

                </ModalContent>
            </Modal >
        </>
    );
};

const Slider = ({ player, progressData }) => {
    const [isSeeking, setIsSeeking] = useState(false);
    const [playedVideo, setPlayedVideo] = useState();

    useEffect(() => {
        if (isSeeking) return;
        setPlayedVideo(progressData.played);
    }, [progressData.played])

    const handleSeekMouseDown = (e) => {
        if (!isSeeking)
            setIsSeeking(true);
        else
            handleSeekMouseUp(e);
    }

    const handleSeekChange = useCallback((e) => {
        setPlayedVideo(e.target.value);
    }, [setPlayedVideo])

    const handleSeekMouseUp = useCallback(e => {
        setIsSeeking(false);
        player.seekTo(e.target.value, 'fraction')
    }, [player, setIsSeeking])

    return (
        <Box pos='relative'>
            <Input
                css={{
                    '&::-webkit-slider-thumb': {
                        "-webkit-appearance": "none",
                        "appearance": "none",
                        "width": "20px",
                        "height": "20px",
                        "background": "#e53e3e",
                        "cursor": "pointer",
                        "borderRadius": '50px',
                        'transition': 'all 0.2s',
                        "outline": 'none'
                    }, '&::-webkit-slider-thumb:hover': {
                        "width": "25px",
                        "height": "25px",
                    }
                }}
                boxShadow='none!important'
                outline='none!important'
                cursor='pointer'
                border='none'
                p={0}
                h={1}
                bg={`linear-gradient(to right, #e53e3e 0%, #e53e3e ${playedVideo * 100}%,white ${playedVideo * 100}%,white 100%)`}
                type="range" min={0} max={0.999999} step='any' value={playedVideo}
                onMouseDown={handleSeekMouseDown}
                onChange={handleSeekChange}
                onMouseUp={handleSeekMouseUp}
            />
        </Box>
    )
}


const Duration = ({ player, isInFullMode, handleLiveBt }) => {
    if (!player) return null;
    const currentTimeInSec = player.getCurrentTime();
    const currentHours = parseInt(currentTimeInSec / (60 * 60));
    const currentMinutes = round(parseInt((currentTimeInSec - (currentHours * 3600)) / 60), 2);
    const currentSeconds = round(parseInt(currentTimeInSec % 60), 2);

    const totalTimeInSec = player.getDuration() > currentTimeInSec ? player.getDuration() : currentTimeInSec;
    const totalHours = parseInt(totalTimeInSec / (60 * 60));
    const totalMinutes = parseInt((totalTimeInSec - (totalHours * 3600)) / 60);
    const totalSeconds = parseInt(totalTimeInSec % 60);
    return (
        <Flex fontWeight='bold' color={'white'}>
            <Box mr={2}>
                {
                    currentHours > 0 ? addZeroBefore(currentHours) + ":" : ''
                }
                {
                    addZeroBefore(currentMinutes) + ":" + addZeroBefore(currentSeconds)
                }
            </Box>
            <Box>
                {
                    totalHours > 0 ? addZeroBefore(totalHours) + ":" : ''
                }
                {
                    addZeroBefore(totalMinutes) + ":" + addZeroBefore(totalSeconds)
                }
            </Box>
        </Flex>
    )
}

const addZeroBefore = (number) => {
    return number > 9 ? number : "0" + number;
}

const PlayPauseBt = ({ handlePause, handlePlay, isPause }) => {
    return (
        <Button size='sm' colorScheme="red" onClick={isPause ? handlePlay : handlePause}>
            {
                isPause ?
                    <IoPlayOutline />
                    :
                    <IoPauseOutline />
            }
        </Button>
    )
}
