import React, { useReducer, useState, useEffect } from "react";
import {
  Box, Button, Flex, FormControl, FormLabel, Input, Image, Spacer, Grid
} from "@chakra-ui/react";
import { useDispatch, useSelector } from 'react-redux';
import { FormReducer } from "../../utils/FormReducer";
import { useHistory } from "react-router-dom";
import { EditIcon, AddIcon, CheckCircleIcon } from '@chakra-ui/icons'
import { useAppContext } from "../../App/Context";
import { UserProfileModal } from './UserProfileModal'
import { UserInterestModal } from './UserInterestModal'
import _ from 'lodash';

export const ProfileComponent = (props) => {
    const {user} = useSelector((state) => ({
        user: state.user.user,
    }))

    const [userProfileModal, changeUserProfileModal] = useState({modal: false});
    const [userInterestModal, changeUserInterestModal] = useState({modal: false});
    
    const {logout} = useAppContext()

    return  user && (
        <div>
            <Button onClick={logout}>LOGOUT</Button>
            <Flex p={10} justifyContent='center' w='100%' style={{paddingBottom:'15px'}}>
                <Box w='80%' p={6} boxShadow='rgba(0, 0, 0, 0.1) 0px 4px 12px' style={{padding: '0px', borderRadius: '7px'}}>
                    <div style={{padding:'10px'}}>
                        <Flex>
                            <Box p="2" >
                                <Image
                                    borderRadius="full"
                                    border = '2px solid gray'
                                    boxSize="170px"
                                    src="https://joeschmoe.io/api/v1/random"
                                    alt="Segun Adebayo"
                                />
                            </Box>
                            <Box p='2' flex="1" >
                                <Flex justifyContent='center' direction='column' align='center' height='100%'>
                                    <div style={{fontSize: '50px'}}><b>{user.name}</b></div>
                                    <div>{user.email}</div>
                                    <div>{user.contact}</div>
                                </Flex>
                            </Box>
                        </Flex>
                    </div>
                </Box>
                {userProfileModal.modal ? 
                    <UserProfileModal userProfileModal={userProfileModal.modal} closeModal={() => changeUserProfileModal({modal: false})}/>
                : null}
                {userInterestModal.modal ? 
                    <UserInterestModal userInterestModal={userInterestModal.modal} closeModal={() => changeUserInterestModal({modal: false})}/>
                : null}
            </Flex>
            <Flex p={10} justifyContent='center' w='100%' style={{paddingBottom:'15px'}}>
                <Box w='80%' p={6} boxShadow='rgba(0, 0, 0, 0.1) 0px 4px 12px' style={{padding: '0px', borderRadius: '7px'}}>
                    <div style={{padding:'10px',display:'flex', justifyContent:'center', alignItems : 'center'}} >
                        <Button leftIcon={<EditIcon />}  onClick={() => changeUserProfileModal({modal: true})} m={5} variant="solid">
                            Edit Profile
                        </Button>
                        <Button leftIcon={<EditIcon />}  onClick={() => changeUserInterestModal({modal: true})} m={5} variant="solid">
                            Add Interest
                        </Button>
                    </div>
                </Box>
            </Flex>
        </div>
    );
};