import firebase from 'firebase'
import { LoadingRef } from '../App/AppProvider';

const firebaseConfig = {
    apiKey: "AIzaSyAlm4wNwiXRt-YCnLyilcgVxYHGzq8Ip74",
    authDomain: "rankplus-c9195.firebaseapp.com",
    projectId: "rankplus-c9195",
    storageBucket: "rankplus-c9195.appspot.com",
    messagingSenderId: "842261365462",
    appId: "1:842261365462:web:372b9f410268da0a02bf77",
    measurementId: "G-4237N5SL2T"
};


if (typeof window !== undefined) {
    try {

        firebase.initializeApp(firebaseConfig)
        // firebase.initializeApp(firebaseConfig)

        const remoteConfig = firebase.remoteConfig();
        remoteConfig.settings.minimumFetchIntervalMillis = 3600000;

        remoteConfig.defaultConfig = {
            "welcome_message": "Welcome To Rankplus"
        };
        const val = remoteConfig.getValue("welcome_message");

        const analytics = firebase.analytics();
        analytics.logEvent("app initialized")


        const messaging = firebase.messaging();
        messaging.getToken({ vapidKey: "BOlyvAqPZwpS1uV25cWWSN8FFvyAcQCRtHeDU1gmWPaQ84QbnJdzZglTJvZ8eYYDzT7lKnPeeRaozZns_bMyveE" })
            .then((currentToken) => {
                if (currentToken) {
                    // Send the token to your server and update the UI if necessary
                    console.log("token fcm", currentToken)
                    window.fcmToken = currentToken
                    LoadingRef.current && LoadingRef.current.updateDevice()
                } else {
                    // Show permission request UI
                    console.log('No registration token available. Request permission to generate one.');
                }
            }).catch((err) => {
                console.log('An error occurred while retrieving token. ', err);
            });

        messaging.onMessage((payload) => {
            console.log('Message received. ', payload);
        });
    } catch (e) {
        console.warn("firebase error catch ", e)
    }

}




export const firebaseClient = firebase;
