import {
    Box,
    Button,
    ButtonGroup,
    Divider,
    HStack,
    Progress,
    Tab,
    Table,
    Tag,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    Tooltip,
    Alert,
    AlertIcon,
    Flex,
    Image,
    IconButton,
} from "@chakra-ui/react";
import React, { useEffect, useMemo, useState } from "react";
import { SectionHeader } from "../../Components/SectionHeader";
import { GoEye } from "react-icons/go";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ErrorChecker } from "../../Components/ErrorChecker";
import { STATUS } from "../../App/Constants";
import _, { find } from "lodash";
import { ROUTES } from "../../Constants/Routes";
import { map } from "lodash";
import { size } from "lodash";
import { getAllPackages, mobileView, webView } from "../../utils/Helper";
import { BsGraphUp } from "react-icons/bs";

export const PerformanceReportPackages = () => {
    const history = useHistory();
    const dispatch = useDispatch();

    const {
        user,
        studentAttemptsStatus,
        getPkgContentsStatus,
        attempts,
        student,
        tests,
        assignments,
        assignmentAttemptsData,
    } = useSelector((state) => ({
        user: state.user.user,
        studentAttemptsStatus: state.package.studentAttemptsStatus,
        getPkgContentsStatus: state.package.getPkgContentsStatus,
        attempts: state.package.attemptsData,
        student: state.user.student,
        tests: state.package.packageContents?.tests,
        assignments: state.package.packageContents?.assignments,
    }));

    const attemptsData = useMemo(() => {
        let attemptsd = getAllPackages(student)
            ? _.filter(
                getAllPackages(student),
                (p) =>
                    size(p?.package?.tests || []) || size(p?.package?.assignments || [])
            )
            : [];

        return map(attemptsd, (a) => {
            let ptests = a?.package?.tests;
            let passignments = a?.package?.assignments;

            ptests = map(ptests, (te) => {
                let ntest = find(tests, (t) => t._id === te.test);

                return Object.assign({}, te, {
                    test: ntest,
                });
            });

            passignments = map(passignments, (te) => {
                let nass = find(assignments, (a) => a._id === te.assignmentId);
                return Object.assign({}, te, {
                    assignmentId: nass,
                });
            });

            if (a?.package?.tests || a?.package?.assignments) {
                return Object.assign({}, a, {
                    package: Object.assign({}, a.package, {
                        tests: ptests,
                        assignments: passignments,
                    }),
                });
            }
            return a;
        });
    }, [assignments, student, tests]);

    const showTests = (pkg) => {
        history.push("/dashboard/test-packages/" + pkg._id);
    };

    let breadcrumbs = [
        { title: "Home", link: "/" },
        { title: "Performance Report", link: ROUTES.PERFORMANCE_REPORT_PACKAGES },
    ];


    const handleOpenPerformance = (pkg) => {
        history.push('/dashboard/package-performance-report/' + pkg.package._id + '?performance=true')
    }

    return (
        <Box>
            <SectionHeader title="Performance Report" breadcrumbs={breadcrumbs} />

            <Box {...mobileView}>
                <Alert alignItems="start">
                    <AlertIcon />
                    Please download Rankplus mobile app to view tests in mobile.
                </Alert>
                <Flex
                    p={5}
                    bg="gray.50"
                    mt={10}
                    as="a"
                    href="https://play.google.com/store/apps/details?id=com.rankplus.live"
                    target={"_blank"}
                    border="0.5px solid #dadada"
                    borderRadius={"md"}
                    alignItems="center"
                >
                    <Image
                        style={{ height: "40px", width: "40px" }}
                        src={require("../../Images/play.png")}
                    />
                    <Text ml={3}>Click here to download android app</Text>
                </Flex>
            </Box>

            <ErrorChecker status={getPkgContentsStatus}>
                <Box
                    {...webView}
                    boxShadow="sm"
                    p="6"
                    borderRadius="20px"
                    background="white"
                >
                    <div>
                        <Table>
                            <Thead>
                                <Tr>
                                    <Th>Sr. No.</Th>
                                    <Th>Package Name</Th>
                                    <Th>Details</Th>
                                    <Th>Total</Th>
                                    <Th>Progress</Th>
                                    <Th>Actions</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {attemptsData.length
                                    ? attemptsData.map((ad, i) => {
                                        const pkg = ad.package;
                                        let totalTest = pkg?.tests?.length;
                                        let totalAssignment = pkg?.assignments?.length;

                                        let attemptedTest = _.filter(
                                            pkg.tests,
                                            (t) => t?.test?.userAttempts.length
                                        ).length;

                                        const attemptedAssignment = _.filter(
                                            pkg?.assignments,
                                            (a) => _.size(a?.assignmentId?.submissions)
                                        ).length;

                                        //const sizeTests = size(item?.package?.tests);
                                        //const sizeAssignments = size(item?.package?.assignments);
                                        //const sizeAttemptedAssignments = size(attemptedAssignments);

                                        //const sizeAttemptedTests = size(attempted);


                                        let value = _.round(
                                            ((attemptedTest + attemptedAssignment) /
                                                (totalTest + totalAssignment)) *
                                            100,
                                            2
                                        );

                                        value =
                                            String(value) === "NaN" || String(value) === "Infinity"
                                                ? 0
                                                : value;
                                        return (
                                            <Tr key={pkg._id}>
                                                <Td>
                                                    <Box
                                                        padding="10px"
                                                        textAlign="center"
                                                        borderRadius="3px"
                                                        width="50px"
                                                        background="#F0F3F4"
                                                    >
                                                        {++i}.
                                                    </Box>
                                                </Td>
                                                <Td>
                                                    <Text fontSize="sm">
                                                        {pkg.name.en}
                                                        {pkg.name.hn && pkg.name.en ? "/" : null}
                                                        {pkg.name.hn}
                                                    </Text>
                                                </Td>
                                                <Td>
                                                    {totalTest && totalTest != 0 ? (
                                                        <Tag
                                                            onClick={() => showTests(pkg)}
                                                            style={{ cursor: "pointer", margin: "2px" }}
                                                        >
                                                            Tests
                                                        </Tag>
                                                    ) : null}
                                                    {totalAssignment && totalAssignment != 0 ? (
                                                        <Tag
                                                            onClick={() => showTests(pkg)}
                                                            style={{ cursor: "pointer", margin: "2px" }}
                                                        >
                                                            Assignments
                                                        </Tag>
                                                    ) : null}
                                                </Td>
                                                <Td>
                                                    <Tag style={{ margin: "2px" }}>
                                                        {attemptedTest +
                                                            attemptedAssignment +
                                                            "/" +
                                                            _.sum([totalTest, totalAssignment])}
                                                    </Tag>
                                                </Td>
                                                <Td>
                                                    <Progress
                                                        size="sm"
                                                        colorScheme={
                                                            value <= 30
                                                                ? "red"
                                                                : value < 60 && value > 30
                                                                    ? "yellow"
                                                                    : "green"
                                                        }
                                                        value={value}
                                                        style={{ borderRadius: "30px", width: "200px" }}
                                                    />
                                                    <HStack justifyContent="space-between">
                                                        <Text fontSize="sm">{value + "%"}</Text>
                                                        <Text fontSize="sm">
                                                            {testPackageStatus(value)}
                                                        </Text>
                                                    </HStack>
                                                </Td>
                                                <Td>
                                                    <HStack>
                                                        <Tooltip placement="top" label="View Performance">
                                                            <IconButton color={'red.500'} icon={<BsGraphUp />}
                                                                onClick={() => handleOpenPerformance(ad)}
                                                            />
                                                        </Tooltip>
                                                    </HStack>
                                                </Td>
                                            </Tr>
                                        );
                                    })
                                    : null}
                            </Tbody>
                        </Table>
                    </div>
                </Box>
            </ErrorChecker>
        </Box>
    );
};

export const testPackageStatus = (progress) => {
    return progress === 100 ? "Complete" : progress === 0 ? "Start" : "Progress";
};
