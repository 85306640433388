import { useToast } from "@chakra-ui/react";
import { find, omitBy, reduce } from "lodash";
import React, { useEffect, useState } from "react";
import { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useIsAuthenticated } from "../App/Context";
import { useLoginModal } from "../App/useLoginModal";
import CartContext from "./useCart";

export const addToCart = (item, toast) => {
  let cart = getCart();
  cart.push(item);
  cart = JSON.stringify(cart);
  localStorage.setItem("userCart", cart);
  toast.closeAll();
  toast({
    title: "Item Added.",
    description: "Your item is added to The Cart.",
    status: "success",
    duration: 2000,
    isClosable: true,
  });
};

export const removeFromCart = (item, toast) => {
  let cart = getCart();
  if (cart.includes(item)) {
    cart.pop(item);
  }
  cart = JSON.stringify(cart);
  localStorage.setItem("userCart", cart);
  toast.closeAll();
  toast({
    title: "Item Removed.",
    description: "Your item is removed from The Cart.",
    status: "error",
    duration: 2000,
    isClosable: true,
  });
};

export const getCart = () => {
  return typeof window === "undefined"
    ? null
    : JSON.parse(localStorage.getItem("userCart")) ?? [];
};

export const CartProvider = ({ children }) => {

  const { student } = useSelector((s) => ({
    student: s.user.student,
  }));

  const isPackagePurchased = useCallback(
    (pkgId) => {
      if (student?.packages?.length) {
        return find(
          student?.packages,
          (p) => (p.package?._id || p.package) === pkgId
        )
          ? true
          : false;
      }
    },
    [student?.packages]
  );

  const finalSubscriptionPrice = useCallback((packageData, subscription) => {
    const price = parseInt(subscription.price || 0);
    const fakePrice = parseInt(subscription.fakePrice || 0);
    const registrationFee = parseInt(packageData.subRegistrationFee || 0)

    const finalPrice = price + registrationFee
    let gst = parseInt(packageData.gst || 0);
    gst = (finalPrice * gst) / 100;

    const discount = fakePrice ? fakePrice - price : 0;
    const topay = finalPrice + gst;

    return { discount, finalPrice, fakePrice, topay, gst };
  }, []);

  const contextValue = useMemo(
    () => ({
      finalSubscriptionPrice,
      isPackagePurchased,
    }),
    [finalSubscriptionPrice, isPackagePurchased]
  );

  return (
    <CartContext.Provider value={contextValue}>{children}</CartContext.Provider>
  );
};
