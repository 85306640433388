import { Box, Text } from '@chakra-ui/react'
// import { ResponsiveLine } from '@nivo/line';
import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { SectionHeader } from '../../Components/SectionHeader'
import { ROUTES } from '../../Constants/Routes';
import { packageReportAction } from '../../redux/reducers/packages';
import { bilingualText, getAllPackages } from '../../utils/Helper';
// import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import Chart from 'chart.js/auto';
import { useQueryParams } from '../../utils/useQueryParams';

export const PackagePerformance = () => {
    const dispatch = useDispatch()
    const params = useParams()
    let query = useQueryParams();
    let isPerformance = query.get('performance')

    const { packageReport, student } = useSelector(state => ({
        packageReport: state.package.packageReport,
        student: state.user.student
    }))

    useEffect(() => {
        if (params.packageId)
            dispatch(packageReportAction({ packageId: params.packageId }))
    }, [params.packageId, dispatch])

    const packages = getAllPackages(student)
    console.log('packages', packages)
    const currentPackage = packages ? _.find(packages, p => p.package?._id === params.packageId) : null

    let breadcrumbs = [
        { title: "Home", link: "/" },
        { title: isPerformance ? "Performance Report" : "My Tests", link: isPerformance ? ROUTES.PERFORMANCE_REPORT_PACKAGES : ROUTES.TEST_PACKAGES },
        { title: bilingualText(currentPackage.package.name), link: "#" },
    ];


    const reports = useMemo(() => {
        if (packageReport) {
            let assignments = packageReport.assignmentResults?.[0]?.data?.length && _.filter(packageReport.assignmentResults[0].data, d => d.assignment.resultPublished).length ?
                _.filter(packageReport.assignmentResults[0].data, d => d.assignment.resultPublished).map(ass =>
                ({
                    name: ass.assignment.title,
                    createdAt: ass.attempt?.createdAt,
                    myScore: ass.attempt?.studentResult?.score || 0,
                    testResult: ass.assignment.result,
                    max: ass.data.maximumMarks || 0,
                })) : [];

            let tests = packageReport.testResults?.[0]?.data?.length && _.filter(packageReport.testResults[0].data, d => d.test.resultPublished)?.length ?
                _.filter(packageReport.testResults[0].data, d => d.test.resultPublished).map(test =>
                ({
                    name: bilingualText(test.test.name),
                    createdAt: test.attempt?.createdAt,
                    myScore: test.attempt?.studentResult?.totalScore || 0,
                    testResult: test.test.testResult,
                    max: test.test.maxMarks || 0,

                })
                )
                :
                []

            let combinedData = _.concat(tests, assignments)



            return {
                labels: combinedData.map(d => d.name),
                datasets: [
                    {
                        data: combinedData.map(d => _.round((d.myScore / d.max) * 100) || 0),
                        label: 'Me',
                        borderColor: '#E74C3C',
                    },
                    {
                        data: combinedData.map(d => d.max && _.round((d.testResult.highestScore / d.max) * 100) || 0),
                        label: 'Topper',
                        borderColor: '#2ECC71',
                    }
                ]
            }

        }
    }, [packageReport])

    return (
        <Box>
            <SectionHeader title={bilingualText(currentPackage.package.name)} breadcrumbs={breadcrumbs} />

            <Box boxShadow="sm" p="6" borderRadius="20px" background="white">
                <Text fontWeight="bold" fontSize='heading'>
                    Performance Report
                </Text>
                <br /><br />
                <Box>
                    <MyGraph data={reports} />
                </Box>
                <br />
            </Box>
        </Box>
    )
}

const MyGraph = ({ data }) => {

    useEffect(() => {
        let myChart
        if (data) {
            myChart = new Chart(document.getElementById("line-chart"), {
                type: 'line',
                responsive: true,
                data,
                options: {
                    responsive: true,
                    interaction: {
                        mode: 'index',
                        intersect: false,
                    },
                    stacked: false,
                    plugins: {
                        title: {
                            display: true,
                            text: 'My performance compared to topper'
                        }
                    },
                    scales: {
                        x: {
                            display: true,
                            title: {
                                display: true,
                                text: 'Test / Assignment'
                            }
                        },
                        y: {
                            display: true,
                            title: {
                                display: true,
                                text: 'Percentage'
                            }
                        }
                    }
                }
            })
        }

        return () => myChart?.destroy()
    }, [data])

    return (
        <Box display={'block'} minHeight={300} pos='relative'>
            <canvas id="line-chart"></canvas>
            <br /><br />
        </Box>
    )
}