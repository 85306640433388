import { Box, VStack, Text } from '@chakra-ui/react'
import {
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    BreadcrumbSeparator,
} from "@chakra-ui/react"
import _ from 'lodash';
import React, { useEffect } from 'react'
import { useHistory, useLocation, useParams } from 'react-router'
import { Link } from "react-router-dom";

export const SectionHeader = ({ title, breadcrumbs, style }) => {
    const location = useLocation()

    const history = useHistory();
    return (
        <Box marginBottom="2rem">
            {title ? <Text fontSize='heading' style={{ fontWeight: 'bold', marginBottom: '5px' }}>{title}</Text> : null}
            {
                breadcrumbs ?
                    <Breadcrumb fontSize="sm" color='rgba(0,0,0,0.5)'>
                        {breadcrumbs.map((b, i) => {
                            let link = () => b.link ? b.link == 'goBack' ? history.goBack() : history.push(b.link) : ''
                            return <BreadcrumbItem key={i} >
                                <BreadcrumbLink onClick={() => link()} >{b.title}</BreadcrumbLink>
                            </BreadcrumbItem>
                        })}
                    </Breadcrumb> :
                    null
            }
        </Box>
    )
}
